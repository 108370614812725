<template>
  <BasicModal
    :button-right-disabled="tagError">
    <template #content>
      <BasicInputField ref="tagInput"
                       :field-title="'Tag'"
                       :field-name="'tag'"
                       :field-type="'text'"
                       :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                       :callback="validateTag"
                       :placeholder="'Voer tag in'"
                       class="tag-field"/>
    </template>
  </BasicModal>
</template>

<script>
import BasicModal from '@/components/elements/basicModal/BasicModal'
import { ref } from '@vue/reactivity'
import BasicInputField from '@/components/elements/inputFields/BasicInputField'
import { computed } from '@vue/runtime-core'

export default {
  name: 'CreateNewTag',
  components: {
    BasicInputField,
    BasicModal,
  },
  props: {
    onTagInput: {
      type: Function,
      required: false,
      default: () => {}
    },

    // TODO: Pass the API error to the basic input as well.
    apiErrorText: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const tagInput = ref(null)
    const input = ref('')
    const tagError = computed(() => input.value.length === 0)

    function validateTag(tag) {
      input.value = tag.data
      const isInputValid = validateField()
      if (isInputValid) {
        props.onTagInput(tag)
      }
    }

    function validateField() {
      // The field should not be valid if it's invalid or empty.
      return tagInput.value.$refs.tag.value !== ''
    }

    return {
      tagError,
      tagInput,
      validateTag,
      validateField
    }
  },
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.tag-field {
  margin-bottom: rem(40);
  text-align: left;
}
</style>
