<template>
  <div :style="{ 'width': fieldWidth }">
    <h3 v-if="fieldTitle" class="form-input-title">{{ fieldTitle }}</h3>
    <label :for="fieldName" class="input-field-label">
      <img v-if="inputIcon && !apiErrorText" class="input-icon" :src="inputIcon" alt="Input icon" />
      <img v-if="inputIconError && apiErrorText" class="input-icon" :src="inputIconError" alt="Input icon" />

      <input :id="fieldName" :ref="fieldName" v-model="input" :name="fieldName" :placeholder="placeholder"
        :type="fieldType" :class="{ 'no-icon': !inputIcon && !apiErrorText }" class="form-input" />
    </label>
    <p v-if="apiErrorText" class="text-error">{{ apiErrorText }}</p>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { watch } from '@vue/runtime-core'

export default {
  name: 'BasicInputField',
  props: {
    fieldTitle: {
      type: String,
      required: false
    },
    fieldName: {
      type: String,
      required: true
    },
    fieldType: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    callback: {
      type: Function,
      default: null,
    },
    fieldWidth: {
      type: String,
      required: false,
      default: '100%'
    },
    inputIcon: {
      type: String,
      required: false,
      default: ''
    },
    inputIconError: {
      type: String,
      required: false,
      default: ''
    },
    apiErrorText: {
      required: false,
      type: String,
      default: ''
    },
    displayApiError: {
      required: false,
      type: Boolean,
      default: true
    },
    value: {
      required: false,
      type: String,
      default: ''
    }
  },
  setup(props) {
    const input = ref(props.value)
    const timeOut = ref(undefined)

    watch(input, () => {
      setDebounceTimeout()
    })

    function setDebounceTimeout() {
      clearTimeout(timeOut.value)
      timeOut.value = setTimeout(sendFieldData, props.debounce)
    }

    function sendFieldData() {
      if (props.callback) {
        props.callback({ fieldName: props.fieldName, data: input.value })
      }
    }

    return {
      input
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.input-icon {
  @include position(absolute, $top: 50%, $left: rem(17));
  height: rem(20);
  transform: translateY(-50%);
  width: rem(22);
}

.input-field-label {
  position: relative;
  width: 100%;
}

.form-input-title {
  color: var(--blue_dark);
  font-size: rem(16);
  font-weight: 500;
  letter-spacing: 0;
  text-align: left;
  line-height: rem(19);
  margin-bottom: rem(8);
  margin-top: rem(32);
}

.form-input {
  border: none;
  border-radius: rem(8);
  background-color: white;
  box-shadow: rem(2) rem(2) 0 0 rgba(0, 0, 0, 0.1);
  color: var(--blue_dark_01);
  font-size: rem(16);
  height: rem(46);
  width: 100%;
  padding-left: rem(50);
}

.form-input.no-icon {
  padding-left: rem(16);
}

.text-error {
  color: var(--red_error);
  font-size: rem(16);
  letter-spacing: 0;
  line-height: rem(19);
  margin: rem(10) 0;
}
</style>
