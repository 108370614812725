<template>
  <div class="content-container">

    <PageTitle title="Tags">
      <template #extra-content>
        <!-- TODO: On click, the 'Send new request' popup is displayed (6.1.0). -->
        <button type="button" class="pink-button create-button" @click="createNewTag()">
          {{ $t('TAGS.BUTTON_TEXT') }}
        </button>
      </template>
    </PageTitle>
    <h1 class="page-section-title section-title">{{ $t('TAGS.OVERVIEW') }}</h1>

    <!-- <Search search-title="Zoeken op titel,partnerlink of tag" :manage-search="handleSearch"/> -->
    <CustomTable
      :options="tagsTableOptions"
      :table-data="tagData.items"
      :pagination-data="tagData"
      :search-function="manageSearch"
      :search-bar-options="
        {
          placeholder: $t('TAGS.SEARCH'),
          searchId: 1
        }"
      :empty-state-data="emptyStateData"
      table-class="tags-table">
      <template #name="props">
        <div>{{ props.rowData.name }}</div>
      </template>
      <template #actions="props">
        <div class="action-button-group">
          <ActionButton :text="$t('DELETE')"
                        :icon="require('../../../assets/icons/icn_delete.svg')"
                        custom-class="button-red"
                        class="action"
                        @click="preDeleteTag(props.rowData)"/>
        </div>
      </template>
      <template #right-side-info>
        <p> <b> {{ tagData.totalItems || 0 }} </b>
          {{ $t('TAGS.TAG_TOTAL') }} </p>
      </template>
    </CustomTable>
    <BasicModal v-if="isBasicVersionDisplayed"/>

    <CreateNewTag v-if="activeModal === MODAL_NAMES.CREATE_TAG"
                  ref="createNewTagModal"
                  :on-tag-input="getNewTag"
                  :api-error-text="''"/>
  </div>

</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
// import Search from '@src/components/elements/selectSchools/Search'
import CustomTable from '@/components/table/CustomTable'
import { getTableOptions, TAGS } from '@/utils/helpers/customTable/CustomTableHelper'
import { useStore } from 'vuex'
import { GET_TAGS, CREATE_TAG, DELETE_TAG } from '@/store/modules/cms/actions'
import ActionButton from '@/components/table/ActionButton'
import { computed, ref } from '@vue/reactivity'
import ModalController, { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import BasicModal from '@/components/elements/basicModal/BasicModal'
import CreateNewTag from '@/components/partials/extraGames/createNewTag.vue'
import { createEmptyTableData } from '@/utils/helpers/customTable/EmptyTableHelper'

export default {
  name: 'Tags',
  components: {
    PageTitle,
    CustomTable,
    BasicModal,
    ActionButton,
    CreateNewTag
  },
  setup() {
    const store = useStore()

    const modalController = ref(new ModalController())

    const activeModal = computed(() => store.getters.getActiveBasicModal)

    const isBasicVersionDisplayed = computed(() =>
      activeModal.value === MODAL_NAMES.DELETE_TAG
    )

    function manageSearch(payload) {
      if (!payload.page) {
        payload.page = 1
      }
      getTags(payload)
    }

    function getTags(payload) {
      store.dispatch(GET_TAGS, payload)
    }

    const emptyStateData = createEmptyTableData('TAGS', createNewTag, require('@/assets/icons/icn_tags.svg'))

    const tagData = computed(() => store.getters.getTags)



    /** Create New Tag */

    const createNewTagModal = ref(null)
    const newTagValue = ref('')

    function createNewTag() {
      modalController.value.setModal(BASIC_MODAL_CONTENT.CREATE_TAG, createTag, null, null, MODAL_NAMES.CREATE_TAG)
    }

    function getNewTag(tag) {
      newTagValue.value = tag.data
    }

    function createTag() {
      if (createNewTagModal.value.validateField()) {
        const tagParams = { name: newTagValue.value }
        //TODO hook up to api call
        store.dispatch(CREATE_TAG, tagParams).then(resposnse => {
          if (Response) {
            manageSearch({})
            modalController.value.resetActiveModal()
            return true
          }
        })
      }
    }

    function preDeleteTag(tag) {
      const name = tag.name
      modalController.value.setModal(BASIC_MODAL_CONTENT.DELETE_TAG(name), deleteTag, null, tag, MODAL_NAMES.DELETE_TAG)
    }

    function deleteTag(tag) {
      store.dispatch(DELETE_TAG, tag.id).then(response => {
        if (response) {
          manageSearch({})
          modalController.value.resetActiveModal()

          return true
        }
      })
      //TODO hook up to api call to delete
    }

    return {
      tagsTableOptions: getTableOptions(TAGS),
      tagData,
      activeModal,
      modalController,
      MODAL_NAMES,
      preDeleteTag,
      createNewTag,
      createTag,
      createNewTagModal,
      getNewTag,
      manageSearch,
      emptyStateData,
      isBasicVersionDisplayed
    }
  },
  template: 'Tags'
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.create-button {
  font-size: rem(16);
  padding: rem(11) rem(22);
}
</style>
